import React from 'react';

const PrivacyPolicy = () => {
    return (
        <>
            <div className="container">
            <h1>StoVoo Privacy Policy</h1>
            <p>
                <strong>Effective Date: November 24, 2024</strong>
            </p>

            <p>
                Welcome to StoVoo! We value your trust, and we want to make sure you understand how we
                collect, use, and safeguard your personal information. Please read our Privacy Policy to
                learn about your rights and how we respect your privacy.
            </p>

            <h2>1. Introduction</h2>
            
            <p>
                StoVoo is committed to protecting the privacy of our users. This Privacy Policy explains
                how we collect, use, share, and protect your information. By using StoVoo's website and
                services, you agree to the terms outlined in this policy. We prioritize transparency and
                trust, and we aim to ensure that all users are fully aware of our practices when it comes
                to data collection and usage.
            </p>

            <p>
                We understand the importance of your personal data and strive to use best-in-class
                industry practices to ensure your information remains secure. We are constantly updating
                our privacy practices to align with global standards and regulations, including GDPR and
                other relevant privacy laws. Your privacy is not just a requirement, but a cornerstone of
                our relationship with you.
            </p>

            <p>
                We believe in the importance of user rights and encourage our users to understand and
                take control of their information. Whether you have questions or require clarifications
                about how we use your information, we are always here to assist. Read on to know more
                about the specific measures we take to protect your data and your rights as a user of
                StoVoo.
            </p>

            <h2>2. Information We Collect</h2>
            <p>We collect different types of information to provide you with the best possible experience. This includes:</p>
            <ul>
                <li>
                <strong>Personal Information</strong>: Such as your name, email address, phone number, delivery address, and payment information. This information is essential for providing services such as product delivery, customer support, and account management.
                </li>
                <li>
                <strong>Usage Information</strong>: Data about your activities on our platform, such as your browsing history, preferences, and interactions. We use this information to better understand how users interact with our platform and to enhance the user experience through personalized services.
                </li>
                <li>
                <strong>Cookies</strong>: We use cookies and similar technologies to enhance your user experience, track website performance, and deliver personalized services. Cookies help us to identify returning users, remember their preferences, and provide a smoother experience.
                </li>
            </ul>

            <p>
                Additionally, we may collect other types of information, such as device information (IP addresses, browser type, etc.), which allows us to troubleshoot technical issues and optimize the performance of our website. We also collect location information to enhance the services provided, such as offering localized content, optimizing delivery times, and ensuring that services are compliant with local regulations.
            </p>

            <h2>3. How We Use Your Information</h2>
            <p>The information we collect is used for various purposes, including:</p>
            <ul>
                <li>To process and fulfill your orders. This includes processing payment, arranging delivery, and keeping you informed on the status of your order.</li>
                <li>
                To personalize your experience on our website and recommend relevant products. By analyzing your usage data and preferences, we can tailor our services to match your interests.
                </li>
                <li>
                To improve our services and customer support. Feedback and usage data help us to optimize the functionality of our platform, add new features, and ensure that you get the best possible experience when using StoVoo.
                </li>
                <li>
                To communicate with you about updates, promotions, and offers. We may send promotional emails or notifications to inform you about new products, special deals, or other opportunities that may interest you.
                </li>
            </ul>

            <p>
                We also use the information collected to prevent fraudulent activities, monitor usage patterns, and perform analyses that help us ensure that our platform is functioning as intended. Your data helps us understand and meet your needs better, ensuring that our services are always evolving in the direction that benefits our users.
            </p>

            <h2>4. Sharing Your Information</h2>
            <p>
                We do not sell your personal information. We may share your information only with trusted third-party partners to help us operate our services. For example:
            </p>
            <ul>
                <li>
                <strong>Service Providers</strong>: We work with delivery companies and payment processors to provide our services to you. These third parties are bound by confidentiality agreements and are prohibited from using your information for any purpose other than to fulfill their service obligations to StoVoo.
                </li>
                <li>
                <strong>Legal Compliance</strong>: If required by law, we may disclose your information to comply with legal obligations. This may include responding to lawful requests from government authorities or complying with a subpoena or similar legal process.
                </li>
            </ul>

            <h2>5. Security</h2>
            <p>
                We take the security of your data seriously. We use various security technologies and procedures to protect your information from unauthorized access, use, or disclosure. This includes encryption, secure servers, and regular security assessments. We also restrict access to personal data to StoVoo employees, contractors, and agents who need that information in order to process it for us and are subject to strict contractual confidentiality obligations.
            </p>

            <p>
                Despite these measures, please be aware that no security system is impenetrable. We cannot guarantee the absolute security of our database, nor can we guarantee that the information you provide won’t be intercepted while being transmitted to us over the Internet. We urge you to take steps to keep your personal information safe by using unique passwords and securing your devices.
            </p>

            <h2>6. Your Rights</h2>
            <p>
                You have rights regarding the personal data we collect. You can:
            </p>
            <ul>
                <li>Request access to your information.</li>
                <li>Correct or update your personal data.</li>
                <li>Request deletion of your information, subject to legal obligations.</li>
                <li>Opt-out of marketing communications at any time.</li>
            </ul>

            <h2>7. Children’s Privacy</h2>
            <p>
                Our platform is not intended for use by children under the age of 16. We do not knowingly
                collect personal information from children. If we become aware that a child has provided
                us with personal information, we will take steps to delete it.
            </p>

            <h2>8. Changes to This Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will be posted on our
                website, and we will notify you of any significant changes that may impact your rights.
            </p>

            <h2>9. Contact Us</h2>
            <p>
                If you have any questions about our Privacy Policy or how we handle your data, please
                contact us at <a href="mailto:contact@stovoo.com">contact@stovoo.com</a>.
            </p>
            <hr />
            <h1>StoVoo Terms and Conditions</h1>

            <p>
                <strong>Effective Date: November 24, 2024</strong>
            </p>

            <p>
                Welcome to StoVoo! Please read our Terms and Conditions carefully before using our
                platform. By accessing and using StoVoo, you agree to comply with and be bound by the
                following terms. These terms govern your use of StoVoo's services, and they are designed
                to ensure that our platform remains a safe and enjoyable space for all users.
            </p>

            <h2>1. Introduction</h2>
            <p>
                By using StoVoo’s website and services, you agree to be bound by these Terms and
                Conditions. These terms govern your use of the services provided by StoVoo. We encourage
                you to read these terms in detail, as they contain important information regarding your
                rights and obligations as a user of our platform. If you do not agree with any part of
                these terms, please do not use our services.
            </p>
            <p>
                StoVoo reserves the right to modify or amend these terms at any time. We will provide
                notice of any changes on our website, and your continued use of StoVoo after such changes
                are posted constitutes your acceptance of the updated terms. It is your responsibility to
                regularly check for updates to these terms.
            </p>

            <h2>2. Account Creation</h2>
            <p>
                To use certain features of our platform, you may need to create an account. You agree to
                provide accurate and complete information and to keep your account details secure. You
                are responsible for all activities that occur under your account. It is important to keep
                your password confidential and to notify us immediately if you suspect unauthorized
                access to your account.
            </p>
            <p>
                Your StoVoo account is personal to you, and you may not transfer or share your account
                with others. We reserve the right to terminate or suspend your account if we detect any
                fraudulent activities or unauthorized use. By creating an account, you confirm that all
                information you provide is true, accurate, and up to date.
            </p>

            <h2>3. Orders and Payments</h2>
            <ul>
                <li>
                <strong>Ordering</strong>: By placing an order, you agree to pay the listed price and
                any applicable taxes. You are responsible for ensuring that all information related to
                your order, including the delivery address, is accurate. We cannot be held responsible
                for delays or issues caused by incorrect information provided during the ordering
                process.
                </li>
                <li>
                <strong>Payments</strong>: We accept payment through various secure methods. Payment
                details are processed securely, and we do not store full credit card information. By
                providing payment information, you authorize us to charge the applicable fees to the
                designated payment method. If we are unable to process payment for any reason, your
                order may be delayed or canceled.
                </li>
            </ul>
            <p>
                StoVoo reserves the right to change prices at any time. However, any changes will not
                affect orders that have already been placed. We make every effort to ensure that pricing
                information is accurate, but we cannot guarantee that there will be no errors. In the
                event of a pricing error, we will notify you and give you the option to proceed with the
                order at the correct price or to cancel the order.
            </p>

            <h2>4. Delivery</h2>
            <p>
                We work with trusted partners to deliver your orders promptly. Delivery times may vary
                depending on your location and the availability of our partners. We strive to ensure that
                all orders are delivered on time, but please note that unforeseen circumstances may
                occasionally cause delays. StoVoo will not be held responsible for delays caused by
                factors outside our control, such as weather conditions, traffic, or operational issues
                with our delivery partners.
            </p>
            <p>
                We encourage you to provide clear and complete delivery instructions to ensure timely
                delivery of your order. If you experience any issues with your delivery, please contact
                us, and we will work to resolve the problem as soon as possible. Your satisfaction is
                important to us, and we are committed to providing the best possible service.
            </p>

            <h2>5. User Conduct</h2>
            <p>
                You agree not to use our services for any unlawful or abusive purposes. You may not
                interfere with the operation of our website or attempt to access systems without
                permission. Engaging in activities such as spamming, hacking, or distributing malware is
                strictly prohibited. Any misuse of our platform will result in the termination of your
                account and potential legal action.
            </p>
            <p>
                StoVoo aims to create a safe and enjoyable experience for all users. We expect all users
                to behave respectfully towards others and to refrain from abusive, offensive, or
                disruptive behavior. Failure to comply with these expectations may result in restricted
                access to our platform, account suspension, or other actions deemed necessary by StoVoo
                to protect the integrity of our services.
            </p>

            <h2>6. Intellectual Property</h2>
            <p>
                All content on StoVoo, including logos, graphics, and text, is the property of StoVoo and
                may not be used without permission. Unauthorized use of our content may result in legal
                action. You may not reproduce, distribute, or create derivative works based on the
                content of StoVoo without prior written consent.
            </p>
            <p>
                We respect the intellectual property rights of others, and we expect our users to do the
                same. If you believe that your intellectual property rights have been infringed upon by
                content on StoVoo, please contact us with the relevant details, and we will investigate
                the matter promptly.
            </p>

            <h2>7. Limitation of Liability</h2>
            <p>
                StoVoo is not liable for any damages arising from the use or inability to use our
                services. We provide our platform "as is," without warranties of any kind. While we
                strive to ensure that our services are always available and functioning correctly, we
                cannot guarantee that the platform will be free from interruptions, errors, or security
                vulnerabilities.
            </p>
            <p>
                StoVoo will not be liable for any direct, indirect, incidental, or consequential damages,
                including but not limited to lost profits, loss of data, or damages resulting from
                unauthorized access to or use of our systems. By using our platform, you acknowledge and
                agree that your use of StoVoo is at your own risk.
            </p>

            <h2>8. Termination</h2>
            <p>
                We reserve the right to terminate or suspend your access to StoVoo services for any
                violation of these terms or for any other reason, at our discretion. If your account is
                terminated, you may not create another account without our permission. We also reserve
                the right to discontinue our services at any time, without prior notice.
            </p>
            <p>
                If your account is terminated, you must immediately cease all use of StoVoo, and any
                rights granted to you under these terms will be terminated. StoVoo will not be liable to
                you or any third party for any termination of your account or access to our services.
            </p>

            <h2>9. Changes to Terms</h2>
            <p>
                We may modify these Terms and Conditions from time to time. Changes will be posted on our
                website, and continued use of our services constitutes acceptance of the new terms. We
                encourage you to review these terms periodically to stay informed about any updates or
                changes. If you do not agree with any changes to these terms, you should stop using
                StoVoo immediately.
            </p>
            <p>
                We are committed to transparency and to keeping our users informed about any updates that
                may affect their use of our platform. If you have any questions about these terms or how
                they may impact you, please do not hesitate to contact us.
            </p>
            
            <h2>10. Contact Us</h2>
            <p>
                If you have any questions about our Terms and Conditions, please contact us at{' '}
                <a href="mailto:contact@stovoo.com">contact@stovoo.com</a>. We are always here to assist
                you and ensure that you have a positive experience using StoVoo. Your feedback is
                valuable to us, and we encourage you to reach out with any questions, comments, or
                concerns.
            </p>
            </div>
        </>
    );
};

export default PrivacyPolicy;