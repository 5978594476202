import React, { Fragment } from "react"
import { Routes, Route } from "react-router-dom"
import Home from "../pages/Home"
import Login from "../pages/Login"
import Success from "../pages/Success"
import Signedup from "../pages/Signedup"
import RegistrationWrapper from "../pages/RegistrationWrapper"
import PrivacyPolicy from "../pages/PrivacyPolicy"
const AppRouters = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/success" element={<Success />} />
        <Route path="/signedup" element={<Signedup />} />
        <Route path="/registration" element={<RegistrationWrapper />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Fragment>
  )
}

export default AppRouters
