import React, { useState } from "react"
import Signup from "./Signup"
import EmailVerify from "./EmailVerify"
import NumberVerify from "./NumberVerify"
import NumberVerifyCode from "./NumberVerifyCode"
import Registration from "./Registration"

const RegistrationWrapper = () => {
  const [step, setStep] = useState(0)
  function getComponent() {
    switch (step) {
      case 0:
        return <Signup setStep={setStep} />
      case 1:
        return <EmailVerify setStep={setStep} />
      case 2:
        return <NumberVerify setStep={setStep} />
      case 3:
        return <NumberVerifyCode setStep={setStep} />
      case 4:
        return <Registration />

      default:
        return "Unknown step"
    }
  }
  return <>{getComponent()}</>
}

export default RegistrationWrapper
