import React from "react"
import CloseIcon from "../assets/images/close-icon.png"
import hidden from "../assets/images/hidden.png"
import { Link } from "react-router-dom"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import { useNavigate } from "react-router-dom"

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .required("Required")
    .min(2, "Too Short!")
    .max(50, "Too Long!"),
  email: Yup.string().email("Invalid email").required("Required"),
  checkbox: Yup.bool().oneOf([true], "Required"),
})

const Login = () => {
  const [passwordType, setPasswordType] = React.useState("password")
  const [openSnackbar, closeSnackbar] = useSnackbar()
  let navigate = useNavigate()

  const loginCall = (data) => {
    axios
      .post(process.env.REACT_APP_URL + "auth/login", null, {
        params: { email: data.email, password: data.password },
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data.data))
        navigate(`/success`)
      })
      .catch((err) => {
        console.log("err", err.response.data.message)
        openSnackbar(err.response.data.message)
      })
  }

  return (
    <div className="login box-wrap">
      <div className="center-box">
        <div className="form-box">
          <div className="head">
            <h3>Login to StoVoo</h3>
          </div>
          <Formik
            initialValues={{
              password: "",
              email: "",
              checkbox: false,
            }}
            validationSchema={LoginSchema}
            onSubmit={(values) => {
              loginCall(values)
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="form-main">
                  <div className="form-input">
                    <label>Email Address</label>
                    <span className="icon">
                      <img
                        src={CloseIcon}
                        alt="icon"
                        onClick={() => {
                          setFieldValue("email", "")
                        }}
                      />
                    </span>
                    <Field
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Richoco@gmail.com"
                    />
                    {errors.email && touched.email ? (
                      <div className="input-error">{errors.email}</div>
                    ) : null}

                    {/* <input type="text" placeholder="Richoco@gmail.com" /> */}
                  </div>
                  <div className="form-input">
                    <label>Password</label>
                    <span className="icon">
                      <img
                        src={hidden}
                        alt="icon"
                        onClick={() => {
                          setPasswordType(
                            passwordType === "password" ? "text" : "password"
                          )
                        }}
                      />
                    </span>
                    <Field
                      id="password"
                      type={passwordType}
                      name="password"
                      placeholder="************"
                    />
                    {errors.password && touched.password ? (
                      <div className="input-error">{errors.password}</div>
                    ) : null}
                  </div>

                  <div className="flex-checkbox">
                    <Field
                      id="checkbox"
                      type="checkbox"
                      name="checkbox"
                      className="mtr-checkbox"
                    />
                    <div>
                      <label for="checkbox">
                        I have read and accept the Terms of use
                      </label>

                      {errors.checkbox && touched.checkbox ? (
                        <div className="input-error">
                          <br />
                          {errors.checkbox}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="form-footer">
                  {/* <Link to="/success">*/}
                  <button className="account-submit" type="submit">
                    Login Now
                  </button>
                  {/* </Link> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default Login
