import React from "react"
import Close from "../assets/images/close.png"
import CloseIcon from "../assets/images/close-icon.png"
import ArrowIcon from "../assets/images/arrow-right.png"
import Video from "../assets/video/signup.mp4"
import { Link } from "react-router-dom"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import { forEach } from "lodash"

const SiginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
})

const Signup = ({ setStep }) => {
  const [openSnackbar, closeSnackbar] = useSnackbar()

  const emaiCheckCall = (data) => {
    axios
      .post(process.env.REACT_APP_URL + "auth/validate-email", null, {
        params: { email: data.email },
      })
      .then((res) => {
        emailCall(data)
      })
      .catch((err) => {
        let text = ""
        forEach(err.response.data.errors, function (element, key) {
          forEach(element, function (e, key) {
            text = text + e + "\n"
          })
        })
        openSnackbar(text)
      })
  }

  const emailCall = (data) => {
    axios
      .post(process.env.REACT_APP_URL + "auth/send_verification_email", null, {
        params: { email: data.email },
      })
      .then((res) => {
        localStorage.setItem("email", data.email)
        localStorage.setItem("email_code", res.data.data.verification_code)
        setStep(1)
      })
      .catch((err) => {
        console.log("err", err.response.data.message)
        openSnackbar(err.response.data.message)
      })
  }
  return (
    <div className="create-account">
      <button className="close">
        <Link to="/">
          <img src={Close} alt="close" />
        </Link>
      </button>
      <div className="content form-box">
        <div className="head">
          <h3>Create Account</h3>
          <p>Please enter your email address to get a verification code</p>
        </div>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={SiginSchema}
          onSubmit={(values) => {
            emaiCheckCall(values)
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className="form-main">
                <div className="form-input">
                  <label>Email</label>
                  <span className="icon">
                    <img
                      src={CloseIcon}
                      alt="close-icon"
                      onClick={() => {
                        setFieldValue("email", "")
                      }}
                    />
                  </span>
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Richoco@gmail.com"
                  />
                  {errors.email && touched.email ? (
                    <div className="input-error">{errors.email}</div>
                  ) : null}
                </div>
              </div>
              <div className="form-footer">
                <button type="submit" className="btn-submit">
                  <img src={ArrowIcon} alt="arrow-right" />
                </button>
                <p>
                  Already have an account?
                  <Link to="/login">Login</Link>
                </p>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <div className="video-bg">
        <video autoPlay="true" loop>
          <source src={Video} type="video/mp4" />
        </video>
      </div>
    </div>
  )
}

export default Signup
