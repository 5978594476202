import React from 'react';
import { AnimationOnScroll  as Animation} from 'react-animation-on-scroll';
import Slider from "react-slick";
// Import css files
import Pro1 from "../../assets/images/s1.png"
import Pro2 from "../../assets/images/s2.png"
import Pro3 from "../../assets/images/s3.png"
import Pro4 from "../../assets/images/s4.png"
import Pro5 from "../../assets/images/s5.png"
const Testimonials = () => {
    let settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 500,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                }
            }
        ]
    };
    return (
        <div className="testimonial-section">
            <div className="container">
                <div className="testimonial-header">
                    <Animation animateIn="animate__fadeInUp">
                        <h3>Testimonials</h3>
                    </Animation>
                    <Animation animateIn="animate__fadeInUp">
                        <p>
                            Our wall of love comprises what people have to say about stovoo; we
                            appreciate all feedback in improving our service & the lives of
                            others.
                        </p>
                    </Animation>
                </div>
            </div>
            <div className="testimonial-slide">
                <Slider {...settings}>
                    <Animation animateIn="animate__fadeInUp">
                        <div className="item">
                            <div className="avatar">

                                <img src={Pro1} alt="avatar" />
                            </div>
                            <p>This app transforms my chef career by streamlining course creation, recipe showcasing, and food product sales. Support is excellent and the UI user-friendly.</p>
                            <h3>Aisha Williams </h3>
                            <span>Chef at StoVoo</span>
                        </div>
                    </Animation>
                    <Animation animateIn="animate__fadeInUp">
                        <div className="item">
                            <div className="avatar">
                                <img src={Pro2} alt="avatar" />
                            </div>
                            <p>As a busy chef, building a site to sell courses and products is tough. This app simplifies it. Payment is efficient and secure, and revenue rises.</p>
                            <h3>Robert White </h3>
                            <span>Chef at StoVoo</span>
                        </div>
                    </Animation>
                    <Animation animateIn="animate__fadeInUp">
                        <div className="item">
                            <div className="avatar">
                                <img src={Pro3} alt="avatar" />
                            </div>
                            <p>The app helps chefs monetize skills, sell products and courses, and reach new audiences. A great addition to any business that wants to grow.</p>
                            <h3>Ryan Mitchell</h3>
                            <span>Chef at StoVoo</span>
                        </div>
                    </Animation>
                    <Animation animateIn="animate__fadeInUp">
                        <div className="item">
                            <div className="avatar">
                                <img src={Pro4} alt="avatar" />
                            </div>
                            <p>The app is flexible for chefs. Course creation, pricing, and storefront design are easy. The platform boosts revenue with a user-friendly experience.</p>
                            <h3>Mia Taylor</h3>
                            <span>Chef at StoVoo</span>
                        </div>
                    </Animation>
                    <Animation animateIn="animate__fadeInUp">
                        <div className="item">
                            <div className="avatar">
                                <img src={Pro5} alt="avatar" />
                            </div>
                            <p>This app is the one-stop-shop for chefs to monetize culinary skills. From courses to product sales, customer support is responsive and the process is stress-free.</p>
                            <h3>Monique Allen</h3>
                            <span>Chef at StoVoo</span>
                        </div>
                    </Animation>
                </Slider>
            </div>
        </div>
    );
};

export default Testimonials;