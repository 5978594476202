import React from "react"
import CloseIcon from "../assets/images/close-icon.png"
import hidden from "../assets/images/hidden.png"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import { forEach } from "lodash"

const registrationSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  user_name: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
  password_confirmation: Yup.string()
    .required("Required")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  checkbox: Yup.bool().oneOf([true], "Required"),
})

const Registration = () => {
  let navigate = useNavigate()
  const [passwordType, setPasswordType] = React.useState("password")
  const [openSnackbar, closeSnackbar] = useSnackbar()
  const registrationCall = (data) => {
    axios
      .post(process.env.REACT_APP_URL + "auth/register", {
        first_name: data.first_name,
        last_name: data.last_name,
        user_name: data.user_name,
        password: data.password,
        password_confirmation: data.password_confirmation,
        email: localStorage.getItem("email"),
        phone_number: localStorage.getItem("number"),
      })
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.data))
        navigate(`/Signedup`)
      })
      .catch((err) => {
        let text = ""
        forEach(err.response.data.errors, function (element, key) {
          forEach(element, function (e, key) {
            text = text + e + "\n"
          })
        })

        console.log("err", err.response.data.message)
        openSnackbar(text)
      })
  }
  return (
    <div className="complete-reg box-wrap">
      <div className="center-box">
        <Formik
          initialValues={{
            first_name: "",
            last_name: "",
            user_name: "",
            password: "",
            password_confirmation: "",
            checkbox: false,
          }}
          validationSchema={registrationSchema}
          onSubmit={(values) => {
            registrationCall(values)
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form>
              <div className="form-box">
                <div className="head">
                  <h3>Complete Registration</h3>
                </div>
                <div className="form-main">
                  <div className="form-half">
                    <div className="form-input">
                      <label>First name</label>
                      <Field type="text" name="first_name" placeholder="John" />
                      {errors.first_name && touched.first_name ? (
                        <div className="input-error">{errors.first_name}</div>
                      ) : null}
                      {/* <input type="text" placeholder="John" /> */}
                    </div>
                    <div className="form-input">
                      <label>Last name</label>
                      <Field type="text" name="last_name" placeholder="Doe" />
                      {errors.last_name && touched.last_name ? (
                        <div className="input-error">{errors.last_name}</div>
                      ) : null}
                      {/* <input type="text" placeholder="Doe" /> */}
                    </div>
                  </div>
                  <div className="form-input">
                    <label>User name or Brand name</label>
                    <span className="icon">
                      <img
                        src={CloseIcon}
                        alt="icon"
                        onClick={() => {
                          setFieldValue("user_name", "")
                        }}
                      />
                    </span>
                    <Field
                      type="text"
                      name="user_name"
                      placeholder="@ JohnDoe"
                    />
                    {errors.user_name && touched.user_name ? (
                      <div className="input-error">{errors.user_name}</div>
                    ) : null}
                    {/* <input type="text" placeholder="@ JohnDoe" /> */}
                  </div>
                  <div className="form-input">
                    <label>Password</label>
                    <span className="icon">
                      <img
                        src={hidden}
                        alt="icon"
                        onClick={() => {
                          setPasswordType(
                            passwordType === "password" ? "text" : "password"
                          )
                        }}
                      />
                    </span>
                    <Field
                      id="password"
                      type={passwordType}
                      name="password"
                      placeholder="************"
                    />
                    {errors.password && touched.password ? (
                      <div className="input-error">{errors.password}</div>
                    ) : null}
                    {/* <input type="text" placeholder="************" /> */}
                  </div>
                  <div className="form-input">
                    <label>Confirm password</label>
                    <span className="icon">
                      <img
                        src={hidden}
                        alt="icon"
                        onClick={() => {
                          setPasswordType(
                            passwordType === "password" ? "text" : "password"
                          )
                        }}
                      />
                    </span>
                    <Field
                      id="password2"
                      type={passwordType}
                      name="password_confirmation"
                      placeholder="************"
                    />
                    {errors.password_confirmation &&
                    touched.password_confirmation ? (
                      <div className="input-error">
                        {errors.password_confirmation}
                      </div>
                    ) : null}
                  </div>
                  <div className="flex-checkbox">
                    <Field
                      id="checkbox"
                      type="checkbox"
                      name="checkbox"
                      className="mtr-checkbox"
                    />

                    <div>
                      <label for="checkbox">
                        I have read and accept the Terms of use
                      </label>

                      {errors.checkbox && touched.checkbox ? (
                        <div className="input-error">
                          <br />
                          {errors.checkbox}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="form-footer">
                  {/* <Link to="/Signedup"> */}
                  <button type="submit" className="account-submit">
                    Create Account
                  </button>
                  {/* </Link> */}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default Registration
