import React from "react"
import "./assets/scss/app.scss"
import "animate.css/animate.min.css"
import { BrowserRouter as Router } from "react-router-dom"
import AppRouters from "./routers/AppRouters"
import SnackbarProvider from "react-simple-snackbar"

function App() {
  return (
    <div className="App">
      <Router>
        <SnackbarProvider>
          <AppRouters />
        </SnackbarProvider>
      </Router>
    </div>
  )
}

export default App
