import React from "react"
import arrowRight from "../assets/images/arrow-right.png"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import * as Yup from "yup"
import { Formik, Field, Form } from "formik"

const EmailSchema = Yup.object().shape({
  num1: Yup.number("Invalid number").required("Required"),
  num2: Yup.number("Invalid number").required("Required"),
  num3: Yup.number("Invalid number").required("Required"),
  num4: Yup.number("Invalid number").required("Required"),
})

const EmailVerify = ({ setStep }) => {
  const email = localStorage.getItem("email")
  const [openSnackbar, closeSnackbar] = useSnackbar()

  const emailCall = (data) => {
    axios
      .post(process.env.REACT_APP_URL + "auth/send_verification_email", null, {
        params: { email: email },
      })
      .then((res) => {
        localStorage.setItem("email_code", res.data.data.verification_code)
        openSnackbar("Code Sended")
      })
      .catch((err) => {
        console.log("err", err.response.data.message)
        openSnackbar(err.response.data.message)
      })
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() !== "backspace") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  return (
    <div className="verify-email box-wrap">
      <Formik
        initialValues={{
          num1: "",
          num2: "",
          num3: "",
          num4: "",
        }}
        validationSchema={EmailSchema}
        onSubmit={(values) => {
          const emailCode = localStorage.getItem("email_code")
          if (
            emailCode[0] == values.num1 &&
            emailCode[1] == values.num2 &&
            emailCode[2] == values.num3 &&
            emailCode[3] == values.num4
          ) {
            setStep(2)
          } else {
            openSnackbar("Invalid OTP")
          }
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <div className="center-box">
              <div className="form-box">
                <div className="head">
                  <h3>Verify email address</h3>
                  <p>
                    Please enter your email address to get a verification code
                  </p>
                </div>
                <div className="form-main">
                  <div className="digit-boxes">
                    <div className="height-80">
                      <Field
                        name="num1"
                        type="number"
                        placeholder=""
                        className="digit-box success"
                        onKeyUp={handleEnter}
                      />
                      {errors.num1 && touched.num1 ? (
                        <div className="input-error">{errors.num1}</div>
                      ) : null}
                    </div>
                    <div className="height-80">
                      <Field
                        name="num2"
                        placeholder=""
                        type="number"
                        className="digit-box success"
                        onKeyUp={handleEnter}
                      />
                      {errors.num2 && touched.num2 ? (
                        <div className="input-error">{errors.num2}</div>
                      ) : null}
                    </div>
                    <div className="height-80">
                      <Field
                        name="num3"
                        type="number"
                        placeholder=""
                        className="digit-box success"
                        onKeyUp={handleEnter}
                      />
                      {errors.num3 && touched.num3 ? (
                        <div className="input-error">{errors.num3}</div>
                      ) : null}
                    </div>
                    <div className="height-80">
                      <Field
                        name="num4"
                        placeholder=""
                        type="number"
                        className="digit-box error"
                      />
                      {errors.num4 && touched.num4 ? (
                        <div className="input-error">{errors.num4}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="hint">
                    <p>Please enter a verification code sent to {email}</p>
                  </div>
                </div>
                <div className="form-footer">
                  <button className="btn-submit">
                    <img src={arrowRight} alt="arrow-right" />
                  </button>
                  <p>
                    Didn't get code?
                    <a href="#" onClick={emailCall}>
                      Resend
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EmailVerify
