import React from "react"
import arrowRight from "../assets/images/arrow-right.png"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import { useParams } from "react-router-dom"
import { useSnackbar } from "react-simple-snackbar"
import axios from "axios"

const NumberSchema = Yup.object().shape({
  num1: Yup.number("Invalid number").required("Required"),
  num2: Yup.number("Invalid number").required("Required"),
  num3: Yup.number("Invalid number").required("Required"),
  num4: Yup.number("Invalid number").required("Required"),
})

const NumberVerifyCode = ({ setStep }) => {
  const number = localStorage.getItem("number")
  const params = useParams()
  const [openSnackbar, closeSnackbar] = useSnackbar()

  const numberVerifyCall = (data) => {
    axios
      .post(process.env.REACT_APP_URL + "auth/send_otp", null, {
        params: { phone_number: params.number },
      })
      .then((res) => {
        if (res.data.success === true) {
          openSnackbar("Code Sended")
          localStorage.setItem("number", params.number)
          localStorage.setItem("number_code", res.data.data.otp)
        } else {
          openSnackbar(res.data.message)
        }
      })
      .catch((err) => {
        console.log("err", err.response.data.message)
        openSnackbar(err.response.data.message)
      })
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() !== "backspace") {
      const form = event.target.form
      const index = [...form].indexOf(event.target)
      form.elements[index + 1].focus()
      event.preventDefault()
    }
  }
  return (
    <div className="verify-number box-wrap">
      <Formik
        initialValues={{
          num1: "",
          num2: "",
          num3: "",
          num4: "",
        }}
        validationSchema={NumberSchema}
        onSubmit={(values) => {
          const numberCode = localStorage.getItem("number_code")
          if (
            numberCode[0] == values.num1 &&
            numberCode[1] == values.num2 &&
            numberCode[2] == values.num3 &&
            numberCode[3] == values.num4
          ) {
            setStep(4)
          } else {
            openSnackbar("Invalid OTP")
          }
        }}
      >
        {({ errors, touched, setFieldValue }) => (
          <Form>
            <div className="center-box">
              <div className="form-box">
                <div className="head">
                  <h3>Verify phone number</h3>
                </div>
                <div className="form-main">
                  <div className="digit-boxes">
                    <div className="height-80">
                      <Field
                        name="num1"
                        type="number"
                        placeholder=""
                        className="digit-box success"
                        onKeyUp={handleEnter}
                      />
                      {errors.num1 && touched.num1 ? (
                        <div className="input-error">{errors.num1}</div>
                      ) : null}
                    </div>
                    <div className="height-80">
                      <Field
                        name="num2"
                        placeholder=""
                        type="number"
                        className="digit-box success"
                        onKeyUp={handleEnter}
                      />
                      {errors.num2 && touched.num2 ? (
                        <div className="input-error">{errors.num2}</div>
                      ) : null}
                    </div>
                    <div className="height-80">
                      <Field
                        name="num3"
                        type="number"
                        placeholder=""
                        className="digit-box success"
                        onKeyUp={handleEnter}
                      />
                      {errors.num3 && touched.num3 ? (
                        <div className="input-error">{errors.num3}</div>
                      ) : null}
                    </div>
                    <div className="height-80">
                      <Field
                        name="num4"
                        placeholder=""
                        type="number"
                        className="digit-box error"
                      />
                      {errors.num4 && touched.num4 ? (
                        <div className="input-error">{errors.num4}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="hint">
                    <p>Please enter a verification code sent to {number}</p>
                  </div>
                </div>
                <div className="form-footer">
                  <button type="submit" className="btn-submit">
                    <img src={arrowRight} alt="arrow-right" />
                  </button>
                  <p>
                    Didn't get code?
                    <a href="#" onClick={numberVerifyCall}>
                      Resend
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default NumberVerifyCode
