import React, { useState } from "react"
import closeIcon from "../assets/images/close-icon.png"
import arrowRight from "../assets/images/arrow-right.png"
import { Link } from "react-router-dom"
import { Formik, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import { useSnackbar } from "react-simple-snackbar"
import Flag from "react-world-flags"

const countryList = [
  {
    name: "United States",
    flag_code: "US",
    code: "+1",
  },
  {
    name: "Ghana",
    flag_code: "GH",
    code: "+233",
  },
  {
    name: "Kenya",
    flag_code: "KE",
    code: "+254",
  },
  {
    name: "Nigeria",
    flag_code: "NG",
    code: "+234",
  },
  {
    name: "Rwanda",
    flag_code: "RW",
    code: "+250",
  },
  {
    name: "South Africa",
    flag_code: "ZA",
    code: "+27",
  },
  {
    name: "Tanzania",
    flag_code: "TZ",
    code: "+255",
  },
  {
    name: "Uganda",
    flag_code: "UG",
    code: "+256",
  },
  {
    name: "Zambia",
    flag_code: "ZM",
    code: "+260",
  },
  {
    name: "Canada",
    flag_code: "CA",
    code: "+1",
  },
  {
    name: "United Kingdom",
    flag_code: "GB",
    code: "+44",
  },

  {
    name: "UAE",
    flag_code: "AE",
    code: "+971",
  },
]

const NumberSchema = Yup.object().shape({
  number: Yup.string()
    .required("Required")
    .min(9, "Too Short!")
    .max(14, "Too Long!")
    .test("len", "Only number and + is allowed", (val) =>
      /^[0-9\.\+\/]+$/.test(val)
    ),
})
const NumberVerify = ({ setStep }) => {
  const [openDropdown, setOpenDropdown] = useState(false)
  const [country, setCountry] = useState(countryList[0])

  const toggleClass = () => {
    setOpenDropdown(!openDropdown)
  }

  const [openSnackbar, closeSnackbar] = useSnackbar()

  const numberVerifyCall = (data) => {
    axios
      .post(process.env.REACT_APP_URL + "auth/send_otp", null, {
        params: { phone_number: data.number },
      })
      .then((res) => {
        if (res.data.success === true) {
          localStorage.setItem("number", data.number)
          localStorage.setItem("number_code", res.data.data.otp)
          setStep(3)
        } else {
          openSnackbar(res.data.message)
        }
      })
      .catch((err) => {
        console.log("err", err.response.data.message)
        openSnackbar(err.response.data.message)
      })
  }

  return (
    <div className="enter-number box-wrap">
      <div className="center-box">
        <div className="form-box">
          <div className="head">
            <h3>Your phone number</h3>
            <p>Please enter your phone number to get a verification code</p>
          </div>
          <Formik
            initialValues={{
              number: "",
            }}
            validationSchema={NumberSchema}
            onSubmit={(values) => {
              numberVerifyCall(values)
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <Form>
                <div className="form-main">
                  <div className="form-input">
                    <div className="has-dropdown">
                      <div className="dropdown " onClick={toggleClass}>
                        <Flag code={country.flag_code} height="16" />
                        {/* <img src={country.flag} alt="flag.png" /> */}
                      </div>
                      <div className="field">
                        <label>Phone number</label>
                        <span className="icon">
                          <img
                            src={closeIcon}
                            alt="icon"
                            onClick={() => {
                              setFieldValue("number", "")
                            }}
                          />
                        </span>

                        <Field
                          id="number"
                          type="text"
                          name="number"
                          placeholder="(000)  000  00 00"
                        />
                        {errors.number && touched.number ? (
                          <div className="input-error">{errors.number}</div>
                        ) : null}
                      </div>

                      <div
                        className={
                          openDropdown
                            ? "country-dropdown active"
                            : "country-dropdown"
                        }
                      >
                        <ul>
                          {countryList.map((ele) => (
                            <li>
                              <a
                                href="#"
                                onClick={() => {
                                  setCountry(ele)
                                  setFieldValue("number", ele.code)

                                  toggleClass()
                                }}
                              >
                                <Flag code={ele.flag_code} height="16" />

                                {/* <img src={flag} alt="flag.png" /> */}
                                <span>{ele.name}</span>
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-footer">
                  {/* <Link to="/number-verify-code"> */}
                  <button className="btn-submit" type="submit">
                    <img src={arrowRight} alt="arrow-right" />
                  </button>
                  {/* </Link> */}

                  <p>
                    Already have an account?
                    <Link to="/login">Login</Link>
                  </p>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default NumberVerify
